

















import Vue from 'vue'
import ModalHeader from '../shared/ModalHeader.vue'

export default Vue.extend({
  name: 'EditNotes',
  components: {
    ModalHeader,
  },
  props: {
    note: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      orderNote: this.note,
    }
  },
  methods: {
    saveNote() {
      //TODO: Save data to backend
      this.$emit('close')
    },
  },
})
