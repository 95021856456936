








































































import { cancelOrder, refundOrder, restockLineItems } from '../../lib/order'
import Vue, { PropType } from 'vue'
import {
  LineItem,
  Maybe,
  Order,
  Price,
  PriceInput,
  RestockedLineItemInput,
  RefundLineItemInput,
  Dispute,
  RefundParamsInput,
} from '../../../../shared/types/types'
import { formatPrice } from '../../lib/utils'
import ModalHeader from '../shared/ModalHeader.vue'

export default Vue.extend({
  name: 'CancelOrder',
  components: {
    ModalHeader,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    orderDispute: {
      type: Object as PropType<Dispute>,
      default: null,
    },
    lineItemsAmount: {
      type: Object as PropType<Price>,
      required: true,
    },
    taxAmount: {
      type: Object as PropType<Price>,
      required: true,
    },
    shippingAmount: {
      type: Object as PropType<Price>,
      required: true,
    },
    lineItems: {
      type: Array as PropType<LineItem[]>,
      required: true,
    },
  },
  data() {
    return {
      refundType: 'refund',
      restockItems: false,
      notifyCustomer: true,
      // TODO: Needs to be from Types?
      cancellationReasonTypes: {
        CustomerChangesOrCanceledOrder: 'Customer changed/canceled order',
        FraudulentOrder: 'Fraudulent order',
        ItemsUnavailable: 'Items unavailable',
        PaymentDeclined: 'Payment declined',
        Others: 'Others',
      },
      reasonForCancellation: '',
      isLoading: false,
    }
  },
  computed: {
    isRefundLater(): boolean {
      return this.refundType === 'refund-later'
    },
    formattedPrice() {
      return (price: Price) => formatPrice(price)
    },
    validForCancelOrder(): boolean {
      return !!this.reasonForCancellation
    },
    totalRefundAmount(): Price {
      return {
        amount:
          (this.lineItemsAmount.amount || 0) +
          (this.taxAmount.amount || 0) +
          (this.shippingAmount.amount || 0),
        currency: this.lineItemsAmount.currency,
      }
    },
    showRefundPayment(): boolean {
      return !!(
        (this.totalRefundAmount.amount && !this.orderDispute) ||
        this.orderDispute?.isChargeRefundable
      )
    },
  },
  methods: {
    closeModal(isCancelationSuccessfull?: boolean) {
      this.$emit('close', isCancelationSuccessfull)
    },
    /**
     * Refund, Restock,Cancel an order
     */
    async cancelOrder() {
      this.isLoading = true
      const promises: Array<Promise<Maybe<Order>>> = [
        cancelOrder(
          this.orderId,
          this.reasonForCancellation,
          this.isRefundLater && this.notifyCustomer
        ),
      ]
      if (
        this.totalRefundAmount.amount &&
        this.refundType !== 'refund-later' &&
        (!this.orderDispute || this.orderDispute?.isChargeRefundable)
      ) {
        const refund: RefundParamsInput = {
          params: {
            lineItemsRefundAmount: {
              amount: this.lineItemsAmount.amount,
              currency: this.lineItemsAmount.currency,
            } as PriceInput,
            taxRefundAmount: {
              amount: this.taxAmount.amount,
              currency: this.taxAmount.currency,
            } as PriceInput,
            shippingRefundAmount: {
              amount: this.shippingAmount.amount,
              currency: this.shippingAmount.currency,
            } as PriceInput,
            reason: this.reasonForCancellation,
            lineItems: this.lineItems.map((lineItem) => ({
              id: lineItem?.lineItemId,
              quantity: lineItem?.quantity,
            })) as RefundLineItemInput[],
            notifyCustomer: !this.isRefundLater && this.notifyCustomer,
            isCancellation: true,
          },
        }
        promises.push(refundOrder(this.orderId, refund))
      }
      if (this.restockItems && this.lineItems?.length) {
        promises.push(
          restockLineItems(
            this.orderId,
            this.getRestockedLineItems(this.lineItems)
          )
        )
      }
      try {
        await Promise.all(promises)
        this.$buefy.toast.open({
          message: 'Order has been Canceled!',
          type: 'is-success',
          position: 'is-bottom',
        })
        this.isLoading = false
        this.closeModal(true)
      } catch (error) {
        this.isLoading = false
        this.$buefy.toast.open({
          message: 'Something went Wrong. Please try again later.',
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
    },
    getRestockedLineItems(lineItems: LineItem[]): RestockedLineItemInput[] {
      return lineItems.map((lineItem) => ({
        lineItemId: lineItem.lineItemId,
        quantity: lineItem.quantity,
      })) as RestockedLineItemInput[]
    },
  },
})
