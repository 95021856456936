
























import Vue, { PropType } from 'vue'
import { Risk, RiskLevelEnum } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'RiskAnalysis',
  props: {
    risk: {
      type: Object as PropType<Risk>,
      required: true,
    },
  },
  computed: {
    isLow(): boolean {
      return (
        this.risk.level === RiskLevelEnum.Normal ||
        this.risk.level === RiskLevelEnum.Unknown ||
        this.risk.level === RiskLevelEnum.NotAssessed
      )
    },
    isMedium(): boolean {
      return this.risk.level === RiskLevelEnum.Elevated
    },
    isHigh(): boolean {
      return this.risk.level === RiskLevelEnum.Highest
    },
    barType(): string {
      if (this.isLow) {
        return 'is-info'
      } else if (this.isMedium) {
        return 'is-warning'
      } else if (this.isHigh) {
        return 'is-danger'
      }
      return ''
    },
  },
  methods: {
    getRiskClasses(isPresent: boolean) {
      return { 'text-blue-500': isPresent }
    },
  },
})
