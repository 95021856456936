






























import Vue, { PropType } from 'vue'
import { Fulfillment, Tracker } from '../../../../shared/types/types'
import ModalHeader from '../shared/ModalHeader.vue'
import TrackerCard from './TrackerCard.vue'

export default Vue.extend({
  name: 'TrackerModal',
  components: {
    ModalHeader,
    TrackerCard,
  },
  props: {
    fulfillmentId: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    tracker: {
      type: Object as PropType<Tracker>,
      default: undefined,
    },
  },
  data() {
    return {
      shouldSubmitTracker: false,
      isLoading: false,
      notifyCustomer: true,
    }
  },
  methods: {
    saveTracker() {
      this.shouldSubmitTracker = true
    },
    resetSubmitTracker() {
      this.shouldSubmitTracker = false
    },
    changeLoader(isLoading: boolean) {
      this.isLoading = isLoading
      if (!isLoading) {
        this.close()
      }
    },
    close() {
      this.$emit('close')
    },
    updateFulfillmentTracker(fulfillment: Fulfillment) {
      this.$emit('update-fulfillment-tracker', fulfillment)
      this.changeLoader(false)
    },
  },
})
