





















































import Vue, { PropType } from 'vue'
import Separator from '../shared/Separator.vue'
import { formatPrice } from '../../lib/utils'
import { LineItem, Maybe, Price } from '../../../../shared/types/types'
import { deleteLineItemCustomAttributeKey } from '@/lib/order'
import ItemImage from '../shared/ItemImage.vue'

export default Vue.extend({
  name: 'LineItem',
  components: {
    Separator,
    ItemImage,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    lineItem: {
      type: Object as PropType<LineItem>,
      default: null,
    },
    showSeparator: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      customAttributes: '',
    }
  },
  computed: {
    formattedPrice() {
      return (price: Price) => formatPrice(price)
    },
    parsedCustomAttributes(): Record<string, any> {
      return this.customAttributes ? JSON.parse(this.customAttributes) : {}
    },
  },
  created() {
    this.customAttributes = this.lineItem?.customAttributes
  },
  methods: {
    updateLineItemCustomAttributes(updatedLineItems: Maybe<LineItem>[]) {
      const updatedLineItem = updatedLineItems.find(
        (item) => item?.lineItemId === this.lineItem.lineItemId
      )
      if (updatedLineItem) {
        this.customAttributes = updatedLineItem.customAttributes
      }
    },
    deleteLineItemCustomAttributeKey(key: string) {
      if (this.lineItem.lineItemId) {
        this.$buefy.dialog.confirm({
          title: `Deleting ${key}?`,
          message: `Are you sure you want to delete the property <strong>${key}</strong>? This can't be undone.`,
          confirmText: 'Delete Property',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            try {
              const updatedOrder = await deleteLineItemCustomAttributeKey(
                this.orderId,
                this.lineItem.lineItemId || '',
                key
              )
              if (updatedOrder?.lineItems) {
                this.updateLineItemCustomAttributes(updatedOrder?.lineItems)
              }
              this.$buefy.toast.open({
                message: 'Property deleted!',
                type: 'is-success',
                position: 'is-bottom',
              })
            } catch (error) {
              this.$buefy.toast.open({
                message: 'Something went wrong!',
                type: 'is-danger',
                position: 'is-bottom',
              })
            }
          },
        })
      }
    },
  },
})
