






































import Vue, { PropType } from 'vue'
import pluralize from 'pluralize'
import Separator from '../shared/Separator.vue'
import AddressCard from '../shared/AddressCard.vue'
import EditCustomerEmail from '../shared/EditCustomerEmail.vue'
import { getCustomerOrdersCount } from '@/lib/order'
import { Address, Customer, Order, Maybe } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'CustomerCard',
  components: {
    Separator,
    AddressCard,
  },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      customerOrdersCount: 0,
      isLoading: true,
      customerData: { ...this.customer },
    }
  },
  computed: {
    customerName(): string {
      return `${this.customerData.firstName} ${this.customerData.lastName}`
    },
    totalOrdersText(): string {
      return this.isLoading
        ? 'Loading...'
        : pluralize('Order', this.customerOrdersCount, true)
    },
    additionalAddresses(): Address[] {
      const addresses = []
      if (this.customerData.shippingAddress) {
        addresses.push(this.customerData.shippingAddress)
      }
      if (this.customerData.billingAddress) {
        addresses.push(this.customerData.billingAddress)
      }
      return addresses
    },
    mailto(): string {
      return `mailto:${this.customerData.email}`
    },
    customerDetailsRoute(): string {
      return `/customers/${this.customerData.customerId}`
    },
  },
  async created() {
    if (this.customerData.customerId) {
      try {
        const response = await getCustomerOrdersCount(
          this.customerData.customerId
        )
        this.isLoading = false
        this.customerOrdersCount = response.ordersCount
      } catch (error) {
        this.isLoading = false
        this.$buefy.toast.open({
          message:
            'Failed to fetch the number of customer orders. Please try again later.',
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
    }
  },
  methods: {
    editCustomerContact() {
      this.$buefy.modal.open({
        parent: this,
        component: EditCustomerEmail,
        hasModalCard: true,
        trapFocus: true,
        props: {
          orderId: this.orderId,
          customer: this.customerData,
        },
        events: {
          'update-customer': (order: Maybe<Order>) => {
            if ((order?.customer as Customer).email) {
              this.customerData.email = (order?.customer as Customer).email
            }
          },
        },
      })
    },
  },
})
