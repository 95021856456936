







































































































































import Vue, { PropType } from 'vue'
import pluralize from 'pluralize'
import { Order, Refund } from '../../../../shared/types/types'
import { exchangePrice } from '../../lib/currencies'
import Separator from '../shared/Separator.vue'
import PaymentLineItem from '../orders/PaymentLineItem.vue'
import { PriceList } from '../../types'
import { Price } from '../../../../shared/types/types'
import { CurrencyEnum } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'PaymentSummary',
  components: {
    PaymentLineItem,
    Separator,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    currency(): string {
      return this.order?.finalSubTotalPrice?.currency || ''
    },
    exchangedFee(): Price | null {
      if (this.currency === 'USD') {
        return {
          amount: -(this.order.fee?.amount ?? 0),
          currency: CurrencyEnum.Usd,
        }
      }

      if (
        !this.order ||
        !this.order.fee?.amount ||
        !this.order.fee?.currency ||
        !this.order.exchangeRate?.rate
      ) {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      }

      const amount = this.order.fee?.amount
      const currency = this.order.fee?.currency
      const rate = this.order.exchangeRate?.rate

      const exchangedPrice = exchangePrice(amount, currency, rate, 'from')

      if (!exchangedPrice || !exchangedPrice.amount) {
        return {
          amount: 0,
          currency: this.currency as CurrencyEnum,
        }
      }

      const returnValue = {
        amount: -exchangedPrice?.amount,
        currency: this.currency as CurrencyEnum,
      }
      return returnValue
    },
    exchangedChargebackFees(): Price | null {
      if (this.currency === CurrencyEnum.Usd) {
        return {
          amount: -(this.order.totalChargebackFee?.amount ?? 0),
          currency: CurrencyEnum.Usd,
        }
      }

      if (
        !this.order ||
        !this.order.chargebackFees ||
        !this.order.chargebackFees[0] ||
        !this.order.exchangeRate?.rate
      ) {
        return {
          amount: 0,
          currency: this.currency as CurrencyEnum,
        }
      }
      console.log(
        '🚀 ~ file: PaymentSummary.vue:206 ~ exchangedChargebackFees ~ this.order.exchangeRate?.rate',
        this.order.exchangeRate?.rate
      )

      const amount = this.order.totalChargebackFee?.amount || 0
      const currency =
        this.order?.chargebackFees[0].currency || CurrencyEnum.Usd
      const rate = this.order.exchangeRate?.rate

      const exchangedPrice = exchangePrice(amount, currency, rate, 'from')

      if (!exchangedPrice || !exchangedPrice.amount) {
        return {
          amount: 0,
          currency: this.currency as CurrencyEnum,
        }
      }

      const returnValue = {
        amount: -exchangedPrice?.amount,
        currency: this.currency as CurrencyEnum,
      }
      return returnValue
    },
    exchangedChargebacks(): Price | null {
      if (this.currency === CurrencyEnum.Usd) {
        return {
          amount: this.order.totalChargebacks?.amount || 0,
          currency: CurrencyEnum.Usd,
        }
      }

      if (
        !this.order ||
        !this.order.totalChargebacks ||
        !this.order.exchangeRate?.rate
      ) {
        return {
          amount: 0,
          currency: CurrencyEnum.Usd,
        }
      }

      const amount = this.order.totalChargebacks.amount || 0
      const currency = this.order.totalChargebacks.currency || CurrencyEnum.Usd
      const rate = this.order.exchangeRate?.rate

      const exchangedPrice = exchangePrice(amount, currency, rate, 'from')

      if (
        !exchangedPrice ||
        (typeof exchangedPrice?.amount === 'number' &&
          isNaN(exchangedPrice?.amount))
      )
        return null

      const { amount: exchangedPriceAmount } = exchangedPrice

      const returnValue = {
        amount: -(exchangedPriceAmount || 0),
        currency: this.currency as CurrencyEnum,
      }
      return returnValue
    },
    discountCode(): string | undefined {
      return this.order?.discount?.name
        ? `#${this.order?.discount?.name}`
        : undefined
    },
    totalItemsText(): string {
      return pluralize('Item', this.order?.lineItems?.length, true)
    },
    refundList(): PriceList[] {
      return ((this.order as Order).refunds as Refund[]).map(
        (refund: Refund): PriceList => ({
          id: refund.id || '',
          data: `Reason: ${refund.reason || '-'}`,
          isNegative: true,
          price: refund.amount || {},
        })
      )
    },
    netAmount(): number {
      if (!this.order) return 0
      return (
        (this.order.finalSubTotalPrice?.amount || 0) -
        (this.order.totalDiscountPrice?.amount || 0) -
        (this.order.totalRefund?.amount || 0)
      )
    },
    usdNetAmount(): number {
      if (!this.order || !this.order.usdPrices) return 0
      return (
        (this.order.usdPrices.finalSubTotalPrice?.amount || 0) -
        (this.order.usdPrices.totalDiscountPrice?.amount || 0) -
        (this.order.usdPrices.totalRefund?.amount || 0)
      )
    },
    totalAmount(): number {
      if (!this.order) return 0

      let feeAmount = this.order.fee?.amount ?? 0
      let chargebackFeesAmount = this.order.totalChargebackFee?.amount ?? 0
      if (this.currency !== CurrencyEnum.Usd) {
        feeAmount =
          exchangePrice(
            this.order.fee?.amount || 0,
            this.order.fee?.currency || CurrencyEnum.Usd,
            this.order.exchangeRate?.rate || 1,
            'from'
          )?.amount ?? 0
        chargebackFeesAmount =
          exchangePrice(
            this.order.totalChargebackFee?.amount || 0,
            this.order.totalChargebackFee?.currency || CurrencyEnum.Usd,
            this.order.exchangeRate?.rate || 1,
            'from'
          )?.amount ?? 0
      }
      const orderTotalPriceAmount = this.order.totalPrice?.amount || 0
      const orderTotalChargebacksAmount =
        this.order.totalChargebacks?.amount || 0
      const orderTotalRefundAmount = this.order.totalRefund?.amount || 0

      return (
        orderTotalPriceAmount -
        feeAmount -
        chargebackFeesAmount +
        orderTotalChargebacksAmount -
        orderTotalRefundAmount
      )
    },
    exchangedTotalAmount(): number {
      if (!this.order || !this.order.usdPrices) return 0
      return (
        (this.order.usdPrices.totalPrice?.amount || 0) -
        (this.order.fee?.amount || 0) -
        (this.order.totalChargebackFee?.amount || 0) +
        (this.order.usdPrices.totalChargebacks?.amount || 0) -
        (this.order.usdPrices.totalRefund?.amount || 0) +
        (this.order.usdPrices.shippingRefund?.amount || 0)
      )
    },
  },
  methods: {
    exchangePrice,
  },
})
