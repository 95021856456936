var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-3 p-5"},[_c('div',{staticClass:"text-lg font-semibold mb-4"},[_c('span',{staticClass:"icon rounded-full bg-green-200 text-green-600 mr-1"},[_c('b-icon',{attrs:{"icon":"progress-check","size":"is-small"}})],1),_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('payment-line-item',{attrs:{"title":"Gross Sales","metadata":_vm.totalItemsText,"price":_vm.order.finalSubTotalPrice,"usd-price":_vm.order.usdPrices && _vm.order.usdPrices.finalSubTotalPrice}}),_c('payment-line-item',{attrs:{"title":"Discount","metadata":_vm.discountCode,"price":_vm.order.totalDiscountPrice,"usd-price":_vm.order.usdPrices && _vm.order.usdPrices.totalDiscountPrice}}),(_vm.order.totalRefund)?_c('payment-line-item',{attrs:{"title":"Returns","price":{
      amount: -_vm.order.totalRefund.amount,
      currency: _vm.order.totalRefund.currency,
    },"usd-price":_vm.order.usdPrices && {
        amount: -_vm.order.usdPrices.totalRefund.amount,
        currency: _vm.order.usdPrices.totalRefund.currency,
      }}}):_vm._e(),_c('payment-line-item',{attrs:{"title":"Net","is-bold":true,"price":{
      amount: _vm.netAmount,
      currency: _vm.order.finalSubTotalPrice.currency,
    },"usd-price":{
      amount: _vm.usdNetAmount,
      currency: 'USD',
    },"custom-class":"font-bold"}}),_c('separator'),_c('payment-line-item',{attrs:{"title":"Shipping","metadata":_vm.order &&
      _vm.order.shippingMethod &&
      _vm.order.shippingMethod.zone &&
      _vm.order.shippingMethod.zone.name,"price":_vm.order.shippingPrice,"usd-price":_vm.order.usdPrices && _vm.order.usdPrices.shippingPrice}}),_c('payment-line-item',{attrs:{"title":"Tax","price":_vm.order.taxPrice,"usd-price":_vm.order.usdPrices && _vm.order.usdPrices.taxPrice}}),_c('payment-line-item',{attrs:{"title":"Fee","price-asterisk":true,"price":_vm.exchangedFee,"usd-price":{
      amount: -_vm.order.fee.amount,
      currency: _vm.order.fee.currency,
    },"custom-class":"font-bold"}}),_c('payment-line-item',{attrs:{"title":"Chargeback Fees","price-asterisk":true,"price":_vm.exchangedChargebackFees,"usd-price":{
      amount: -_vm.order.totalChargebackFee.amount,
      currency: 'USD',
    },"custom-class":"font-bold"}}),_c('payment-line-item',{attrs:{"title":"Chargebacks","price-asterisk":true,"price":_vm.exchangedChargebacks,"usd-price":{
      amount: _vm.order.totalChargebacks.amount,
      currency: _vm.order.totalChargebacks.currency,
    },"custom-class":"font-bold"}}),_c('payment-line-item',{attrs:{"title":"Total","is-bold":true,"price-asterisk":true,"price":{
      amount: _vm.totalAmount,
      currency: _vm.currency,
    },"usd-price":{
      amount: _vm.exchangedTotalAmount,
      currency: 'USD',
    },"custom-class":"font-bold"}}),(_vm.order.totalPrice.currency !== 'USD')?_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"text-red-500 mr-1"},[_vm._v("*")]),_vm._v("estimate ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }