























import Vue from 'vue'
import EditNote from '../orders/EditNotes.vue'
import Separator from '../shared/Separator.vue'

export default Vue.extend({
  name: 'OrderNotes',
  components: { Separator },
  props: {
    note: {
      type: String,
      required: true,
    },
    customAttributes: {
      type: String,
      required: true,
    },
  },
  computed: {
    parsedCustomAttributes() {
      return this.customAttributes ? JSON.parse(this.customAttributes) : {}
    },
  },
  methods: {
    editNotes() {
      this.$buefy.modal.open({
        parent: this,
        component: EditNote,
        hasModalCard: true,
        trapFocus: true,
        props: {
          note: this.note,
        },
      })
    },
  },
})
