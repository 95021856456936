




















































































































































































































































































































































import Vue from 'vue'
import NavigateBack from '../../components/shared/NavigateBack.vue'
import LineItem from '../../components/orders/LineItem.vue'
import PaymentSummary from '../../components/orders/PaymentSummary.vue'
// import ChargebackSummary from '../../components/orders/ChargebackSummary.vue'
import CustomerCard from '../../components/customers/CustomerCard.vue'
import OrderNotes from '../../components/orders/OrderNotes.vue'
import LineItemCard from '../../components/orders/LineItemCard.vue'
import CancelOrder from '../../components/orders/CancelOrder.vue'
import RiskAnalysis from '../../components/orders/RiskAnalysis.vue'
import {
  formatDate,
  formatPrice,
  groupedFulfillmentLineItems,
} from '../../lib/utils'
import Separator from '../../components/shared/Separator.vue'
import { getOrder, cancelFulfillment } from '../../lib/order'
import Loader from '../../components/shared/Loader.vue'
import {
  FulfillmentStatusEnum,
  Maybe,
  Order,
  VariantTypeEnum,
  Tracker,
  Fulfillment,
  CurrencyEnum,
  LineItem as ILineItem,
  Refund,
  DisputeStatusEnum,
  PaymentStatusEnum,
} from '../../../../shared/types/types'
import { filterRefundedLineItems } from '../../lib/utils'
import { LineItemExtended, LineItemGroup } from '@/types'
import TrackerModal from '@/components/shared/TrackerModal.vue'

export default Vue.extend({
  name: 'OrderDetails',
  components: {
    NavigateBack,
    LineItem,
    PaymentSummary,
    CustomerCard,
    OrderNotes,
    // ChargebackSummary,
    LineItemCard,
    RiskAnalysis,
    Separator,
    Loader,
  },
  data() {
    return {
      order: {} as Maybe<Order>,
      receivedOrder: false,
      fulfillmentStatusTypes: {
        fulfilled: 'Fulfilled',
        partial: 'Partially fulfilled',
        unfulfilled: 'Unfulfilled',
      },
      orderStatusTypes: {
        open: 'Open',
        archived: 'Archived',
        canceled: 'Canceled',
      },
      paymentStatusTypes: {
        paid: 'Paid',
        unpaid: 'Unpaid',
        refunded: 'Refunded',
        partially_refunded: 'Partially Refunded',
        requires_action: 'Requires Action',
        processing: 'Processing',
      },
      DisputeStatusTypes: {
        needs_response: 'Chargeback open',
        under_review: 'Chargeback submitted',
        charge_refunded: 'Chargeback accepted',
        warning_needs_response: 'Chargeback open',
        warning_under_review: 'Chargeback submitted',
        warning_closed: 'Chargeback closed',
        won: 'Chargeback won',
        lost: 'Chargeback lost',
      },
      VariantTypeEnum,
      fulfilled: {
        physical: [] as LineItemGroup[],
        virtual: [] as LineItemGroup[],
      },
      refundedLineItems: [] as ILineItem[],
    }
  },
  computed: {
    orderId(): string {
      return this.$route.params.id
    },
    currency(): CurrencyEnum {
      return this.order?.totalPrice?.currency || CurrencyEnum.Usd
    },
    showFulfillOrder(): boolean {
      return !this.order?.dispute
    },
    showDispute(): boolean {
      return !!this.order?.dispute
    },
    showDisputeSubmitResponse(): boolean {
      return [
        DisputeStatusEnum.NeedsResponse,
        DisputeStatusEnum.UnderReview,
      ].includes(this.order?.dispute?.status as DisputeStatusEnum)
    },
    showCanceledOrder(): boolean {
      return !!this.order?.canceledAt && this.removedLineItem.length !== 0
    },
    submitDisputeResponseUrl(): string {
      return process.env.VUE_APP_DISPUTE_STRIPE_RESPONSE_URL || ''
    },
    disputeBannerButtonClass(): string {
      const basicProps = 'border-2 rounded border-opacity-30 mr-4'
      switch (this.order?.dispute?.status) {
        case DisputeStatusEnum.NeedsResponse:
        case DisputeStatusEnum.WarningNeedsResponse:
          return `${basicProps} border-yellow-700`
        case DisputeStatusEnum.UnderReview:
        case DisputeStatusEnum.WarningUnderReview:
        case DisputeStatusEnum.WarningClosed:
          return `${basicProps} border-blue-700`
        case DisputeStatusEnum.Lost:
        case DisputeStatusEnum.Won:
          return `${basicProps} border-green-700`
        default:
          return `${basicProps} border-yellow-700`
      }
    },
    disputeButtonText(): string {
      return this.order?.dispute?.status === DisputeStatusEnum.NeedsResponse
        ? 'Submit response'
        : 'Update response'
    },
    disputeMessageType() {
      return (type: string) => {
        let additionalProps = ''
        if (type === 'tag') {
          additionalProps = 'is-light'
        } else if (type === 'button') {
          additionalProps = 'button is-light'
        }
        switch (this.order?.dispute?.status) {
          case DisputeStatusEnum.NeedsResponse:
          case DisputeStatusEnum.WarningNeedsResponse:
            return `is-warning ${additionalProps}`
          case DisputeStatusEnum.UnderReview:
          case DisputeStatusEnum.WarningUnderReview:
          case DisputeStatusEnum.WarningClosed:
            return `is-info ${additionalProps}`
          case DisputeStatusEnum.ChargeRefunded:
          case DisputeStatusEnum.Won:
          case DisputeStatusEnum.Lost:
            return `is-success ${additionalProps}`
          default:
            return 'is-warning is-light'
        }
      }
    },
    disputeTitle(): string {
      switch (this.order?.dispute?.status) {
        case DisputeStatusEnum.NeedsResponse:
          return `The customer has opened a chargeback totaling ${formatPrice({
            amount: this.order?.dispute?.amount,
            currency: this.order?.dispute?.currency,
          })}`
        case DisputeStatusEnum.UnderReview:
          return 'The chargeback response has been submitted'
        case DisputeStatusEnum.ChargeRefunded:
          return ''
        default:
          return ''
      }
    },
    disputeMessage(): string {
      switch (this.order?.dispute?.status) {
        case DisputeStatusEnum.NeedsResponse:
        case DisputeStatusEnum.WarningNeedsResponse:
          return `You have until ${formatDate(
            new Date(Number(this.order?.dispute?.evidenceDetails?.dueBy) * 1000) // converting dueBy from seconds to milliseconds
          )} to submit evidence and dispute the chargeback.`
        case DisputeStatusEnum.UnderReview:
        case DisputeStatusEnum.WarningUnderReview:
          return `The customer's bank will receive the response on ${formatDate(
            new Date(Number(this.order?.dispute?.evidenceDetails?.dueBy) * 1000) // converting dueBy from seconds to milliseconds
          )}. You can update the response any time before that date.`
        case DisputeStatusEnum.ChargeRefunded:
        case DisputeStatusEnum.Lost:
        case DisputeStatusEnum.Won:
          return `A chargeback totalling ${formatPrice({
            amount: this.order?.dispute?.amount,
            currency: this.order?.dispute?.currency,
          })} was accepted.`
        default:
          return ''
      }
    },
    cancelationDateReason(): string {
      return `, Canceled ${this.formattedDate(
        this.order?.canceledAt || ''
      )} - ${this.order?.cancelReason || ''}`
    },
    creationDate(): string {
      return `Created ${this.formattedDate(this.order?.createdAt || '')}`
    },
    showRefund(): boolean {
      const response =
        !!(
          this.order?.canceledAt &&
          !this.order?.dispute &&
          (this.order.charge?.amount?.amount || 0) >
            (this.order.charge?.amountRefunded?.amount || 0)
        ) ||
        !(
          this.order?.charge?.isRefunded ||
          (this.order?.dispute && !this.order.dispute.isChargeRefundable)
        )

      return response
    },
    showRestock() {
      if (this.showRefund) {
        return false
      }

      if (
        this.order?.lineItemQuantities &&
        this.order?.paymentStatus === PaymentStatusEnum.Refunded
      ) {
        const lineItemQuantities = JSON.parse(this.order?.lineItemQuantities)
        return Object.keys(lineItemQuantities).reduce((output, key) => {
          const value = lineItemQuantities[key] as {
            availableToRestockQuantity: number
            restockedQuantity: number
          }
          return output || value.availableToRestockQuantity !== 0
        }, false)
      }

      return false
    },
    refundButtonText(): string {
      var refundButtonText =
        this.order?.netPayment?.amount !== 0 ? 'Refund' : 'Restock'
      return this.showRefund ? refundButtonText : ''
    },
    showCancel(): boolean {
      return (
        !this.order?.canceledAt &&
        this.order?.fulfillmentStatus === FulfillmentStatusEnum.Unfulfilled
      )
    },
    showRiskAnalysis(): boolean {
      return !!(this.order?.charge && this.order.charge?.risk)
    },
    dropdownIcon() {
      return (active: boolean) => (active ? 'menu-up' : 'menu-down')
    },
    formattedDate() {
      return (dateValue: string) => {
        const date = new Date(dateValue || '')
        const today = new Date()
        let preposition = 'at'
        switch (Math.abs(today.getDate() - date.getDate())) {
          case 0:
            preposition = 'at'
            break
          case 1:
            preposition = ''
            break
          default:
            preposition = 'on'
        }
        return `${preposition} ${formatDate(date)}`
      }
    },
    enableEditTracker() {
      return (fulfillmentgroup: LineItemGroup) =>
        !fulfillmentgroup.tracker &&
        fulfillmentgroup.lineItems[0].variantType === VariantTypeEnum.Physical
    },
    /**
     * Filters unFulfilled lineItems
     *
     * @param variantType variant type: physical | virtual
     */
    unFulfilled() {
      return (variantType: VariantTypeEnum) => {
        const unfulfilledLineItems = groupedFulfillmentLineItems(
          FulfillmentStatusEnum.Unfulfilled,
          variantType,
          this.order?.lineItems || [],
          this.order?.fulfillments
        )
        return unfulfilledLineItems.length
          ? unfulfilledLineItems[0].lineItems
          : []
      }
    },
    /**
     * Filters Removed lineItems
     *
     * @param variantType variant type: physical | virtual
     */
    removedLineItem() {
      return (variantType: VariantTypeEnum) => {
        const removedLineItems = groupedFulfillmentLineItems(
          FulfillmentStatusEnum.Unfulfilled,
          variantType,
          this.order?.lineItems || [],
          this.order?.fulfillments
        )
        return removedLineItems.length ? removedLineItems[0].lineItems : []
      }
    },
    showSeparator() {
      return (index: number, lineItems: LineItemExtended[]) => {
        return index !== lineItems.length - 1
      }
    },
  },
  created() {
    this.getOrderDetails()
  },
  methods: {
    /**
     * sets fulfilled lineItems with fulfilled lineItem groups
     *
     * @param variantType variant type: physical | virtual
     */
    setFulfilled(variantType: VariantTypeEnum) {
      return groupedFulfillmentLineItems(
        FulfillmentStatusEnum.Fulfilled,
        variantType,
        this.order?.lineItems || [],
        this.order?.fulfillments
      ).map((lineItem) => {
        lineItem.isCanceling = false
        return lineItem
      })
    },
    cardTitle(title: string, number: number) {
      return `${title} (${number})`
    },
    async getOrderDetails() {
      try {
        this.order = await getOrder(this.orderId)
        this.receivedOrder = true
        if (!this.order?.canceledAt) {
          const filterRefundedLineItemsResponse = filterRefundedLineItems(
            this.order?.lineItems as ILineItem[],
            (this.order?.refunds as Refund[]) || []
          )
          ;(this.order as Order).lineItems =
            filterRefundedLineItemsResponse?.lineItems
          this.refundedLineItems =
            filterRefundedLineItemsResponse.refundedLineItems
        }
        this.fulfilled.virtual = this.setFulfilled(VariantTypeEnum.Virtual)
        this.fulfilled.physical = this.setFulfilled(VariantTypeEnum.Physical)
      } catch (error) {
        console.log('error', error)
        this.$buefy.toast.open({
          message: 'Order not found',
          position: 'is-bottom',
          type: 'is-danger',
        })
      }
    },
    refundOrder() {
      this.$router.push(`/orders/${this.orderId}/refund`)
    },
    restockOrder() {
      this.$router.push(`/orders/${this.orderId}/refund`)
    },
    fulfillOrder(variantType: string) {
      this.$router.push(`/orders/${this.orderId}/fulfillment/${variantType}`)
    },
    /**
     * Opens the cancel order modal and handles the modal's close event
     */
    cancelOrder() {
      this.$buefy.modal.open({
        parent: this,
        component: CancelOrder,
        hasModalCard: true,
        props: {
          lineItemsAmount: this.order?.finalSubTotalPrice || {
            amount: 0,
            currency:
              this.order?.finalSubTotalPrice?.currency || CurrencyEnum.Usd,
          },
          taxAmount: this.order?.taxPrice || {
            amount: 0,
            currency: this.order?.taxPrice?.currency || CurrencyEnum.Usd,
          },
          shippingAmount: this.order?.shippingPrice || {
            amount: 0,
            currency: this.order?.shippingPrice?.currency || CurrencyEnum.Usd,
          },
          orderDispute: this.order?.dispute,
          orderId: this.orderId,
          lineItems: this.order?.lineItems,
        },
        events: {
          close: (isCancelationSuccessfull: boolean) => {
            if (isCancelationSuccessfull) {
              this.$router.push(`/orders`)
            }
          },
        },
      })
    },
    async cancelFulfillment(
      fulfillmentId: string,
      variantType: VariantTypeEnum
    ) {
      this.fulfilled[variantType] = this.fulfilled[variantType].map(
        (fulfillmentGrp) => {
          if (fulfillmentGrp.fulfillmentId === fulfillmentId) {
            fulfillmentGrp.isCanceling = true
          }
          return fulfillmentGrp
        }
      )
      if (this.order?.orderId) {
        try {
          await cancelFulfillment(this.order.orderId, fulfillmentId)
          this.$buefy.toast.open({
            message: 'Order fulfillment canceled',
            type: 'is-success',
            position: 'is-bottom',
          })
        } catch (error) {
          this.$buefy.toast.open({
            message:
              'Failed to cancel the fulfillment. Please try again later.',
            type: 'is-danger',
            position: 'is-bottom',
          })
        }
        this.getOrderDetails()
      }
      this.fulfilled[variantType] = this.fulfilled[variantType].map(
        (fulfillmentGrp) => {
          if (fulfillmentGrp.fulfillmentId === fulfillmentId) {
            fulfillmentGrp.isCanceling = false
          }
          return fulfillmentGrp
        }
      )
    },
    setTrackerToFulfillment(fulfillmentId: string, tracker: Tracker) {
      this.$buefy.modal.open({
        parent: this,
        component: TrackerModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          fulfillmentId,
          orderId: this.orderId,
          tracker,
        },
        events: {
          'update-fulfillment-tracker': this.updateFulfillmentTracker,
        },
      })
    },
    /**
     * Updates fulfillment's tracking information once it's updated
     *
     * @param fulfillment - Fulfillment
     */
    updateFulfillmentTracker(fulfillment: Fulfillment) {
      this.fulfilled.physical = this.fulfilled.physical.map(
        (fulfillmentGroup: LineItemGroup) => {
          if (fulfillmentGroup.fulfillmentId === fulfillment.id) {
            fulfillmentGroup.tracker = fulfillment.tracker || {}
          }
          return fulfillmentGroup
        }
      )
    },
  },
})
