

































import Vue, { PropType } from 'vue'
import ModalHeader from '../shared/ModalHeader.vue'
import { updateOrderCustomer } from '../../lib/order'
import { updateCustomerInfo, validateEmail } from '../../lib/user'
import { Customer, CustomerInput } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'EditCustomerEmail',
  components: {
    ModalHeader,
  },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      email: this.customer.email,
      isFormValid: true,
      isLoading: false,
      updateCustomerProfile: false,
    }
  },
  methods: {
    async validateForm() {
      this.isLoading = true
      try {
        const response = await validateEmail(this.email || '')
        if (response) {
          if (response.isValid === false) {
            this.isFormValid = false
            this.isLoading = false
          } else {
            this.isFormValid = true
            this.isLoading = false
          }
        }
      } catch (e) {
        console.log('error : ', e)
      }
    },
    async updateCustomer() {
      this.isLoading = true
      let customer = { customer: { email: this.email } }
      let editCustomer = {
        customerId: this.customer.customerId,
        email: this.email,
        firstName: this.customer.firstName,
      } as CustomerInput
      try {
        if (this.updateCustomerProfile) {
          await updateCustomerInfo(editCustomer, editCustomer.customerId)
        }
        const order = await updateOrderCustomer(this.orderId, customer)
        this.isLoading = false
        this.$buefy.toast.open({
          message: 'Contact information has been updated!',
          type: 'is-success',
          position: 'is-bottom',
        })
        this.$emit('update-customer', order)
        this.$emit('close')
      } catch (error) {
        this.isLoading = false
        this.$buefy.toast.open({
          message:
            'Failed to update the customer details on the order. Please try again later.',
          type: 'is-danger',
          position: 'is-bottom',
        })
        this.$emit('close')
      }
    },
  },
})
